import * as updatePhoto from "@/api/users/photo";

export default {
	namespaced: true,
	state: {
		photo: null,
		error: null,
	},
	getters: {
		readPhoto: (state) => state.photo,
		readError: (state) => state.error,
	},
	mutations: {
		SET_PHOTO(state, payload) {
			state.photo = payload;
		},
		SET_ERROR(state, payload) {
			state.error = payload;
		},
	},
	actions: {
		async updatePhoto({ commit, getters,rootGetters }) {
			const token = rootGetters['loginStore/readToken'];
			const updatePhotoRes = await updatePhoto
				.updatePhoto(token, getters.readPhoto)
				.then(() => {
					return true;
				})
				.catch((error) => {
					if (error.response) {
						let message =
							error.response.data.message ||
							error.response.data.detail[0].msg;
						message == "Token expired"
							? commit("loginStore/SET_ACCESS_TOKEN", null, {
									root: true,
							  })
							: message
							? commit("SET_ERROR", message)
							: commit("SET_ERROR", "Algo salió mal");
					} else {
						console.log(error);
						commit("SET_ERROR", error);
					}
				});
			return updatePhotoRes;
		},
	},
};
