import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);

store.commit("loginStore/SET_ACCESS_TOKEN", "restore");
store.commit("auth/SET_PROFILE", "restore");

//ROUTES
const routerOptions = [
	{
		path: "/",
		name: "ComingSoon"
	},
	{
		path: "*",
		name: "ComingSoon",
	},
	
];

//LAZY_LOAD
const routes = routerOptions.map((r) => {
	if (!r.children) {
		return {
			...r,
			component: () => import(`@/views/${r.name}/Index.vue`),
		};
	} else {
		const children = r.children.map((c) => {
			let childName = c.name;
			c.component = () => import(`@/views/${r.name}/${childName}.vue`);
			return { ...c };
		});

		return {
			...r,
			component: () => import(`@/views/${r.name}/Index.vue`),
			children: [...children],
		};
	}
});

const router = new VueRouter({
	routes,
});

export default router;
