import * as getRoles from "@/api/roles/get";

export default {
	namespaced: true,
	state: {
		roles: [],
		error: null,
	},
	getters: {
		readRoles: (state) => state.roles,
		readError: (state) => {
			return state.error;
		},
	},
	mutations: {
		SET_ROLES(state, payload) {
			state.roles = payload;
		},
		SET_ERROR(state, payload) {
			state.error = payload;
		},
	},
	actions: {
		async getRoles({ commit, rootGetters }) {
			let token = rootGetters['loginStore/readToken']
			let getRolesRes = await getRoles
				.getRoles(token)
				.then((data) => {
					commit("SET_ROLES", data);
					return true;
				})
				.catch((error) => {
					if (error.response) {
						let message =
							error.response.data.message ||
							error.response.data.detail[0].msg;
						message == "Token expired"
							? commit("loginStore/SET_ACCESS_TOKEN", null, {
									root: true,
							  })
							: message
							? commit("SET_ERROR", message)
							: commit("SET_ERROR", "Algo salió mal");
					} else {
						console.log(error);
						commit("SET_ERROR", error);
					}
				});
			return getRolesRes;
		},
	},
};
