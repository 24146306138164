import cmfApi from "../index";

const API_URL =
	"/votes/pending";

function getVotesPending(token, id) {
	const config = {
		headers: {
			Accept: "application/json",
			Authorization: `Bearer ${token}`,
		},
	};

	const URL = id ? `${API_URL}?emitter_id=${id}` : API_URL;

	return new Promise(function (resolve, reject) {
		cmfApi.get(URL, config).then(
			(response) => {
				var result = response.data;
				resolve(result);
			},
			(error) => {
				reject(error);
			}
		);
	});
}

export { getVotesPending };
