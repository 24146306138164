import * as getReactions from "@/api/reactions/get";

export default {
	namespaced: true,
	state: {
		reactions: [],
		error: null,
	},
	getters: {
		readReactions: (state) => state.reactions,
		readError: (state) => {
			return state.error;
		},
	},
	mutations: {
		SET_REACTIONS(state, payload) {
			state.reactions = payload;
		},
		SET_ERROR(state, payload) {
			state.error = payload;
		},
	},
	actions: {
		async getReactions({ commit, rootGetters }) {
			let token = rootGetters['loginStore/readToken']
			let getReactionsRes = await getReactions
				.getReactions(token)
				.then((data) => {
					commit("SET_REACTIONS", data);
					return true;
				})
				.catch((error) => {
					if (error.response) {
						let message =
							error.response.data.message ||
							error.response.data.detail[0].msg;
						message == "Token expired"
							? commit("loginStore/SET_ACCESS_TOKEN", null, {
									root: true,
							  })
							: message
							? commit("SET_ERROR", message)
							: commit("SET_ERROR", "Algo salió mal");
					} else {
						console.log(error);
						commit("SET_ERROR", error);
					}
				});
			return getReactionsRes;
		},
	},
};
