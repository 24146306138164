//CORE
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

//STYLES
import "./assets/styles/index.scss";
import "./assets/styles/tailwind.css";

//PLUGINS
import _ from "lodash";
import "./plugins/fontawesome";
import "./plugins/sweetAlert";
import "./plugins/crypto";
import "./plugins/tour";

//DIRECTIVES
import "./directives/capitalizeWords";
import "./directives/capitalizeInput";


Vue.config.productionTip = false;

new Vue({
	store,
	router,
	render: (h) => h(App),
}).$mount("#app");
