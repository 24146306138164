import * as getNotifications from "@/api/notifications/get";

export default {
	namespaced: true,
	state: {
		id:null,
		notifications: [],
		totalPages:null,
		error: null,
	},
	getters: {
		readTotal: (state) => state.totalPages,
		readId: (state) => state.id,
		readNotifications: (state) => state.notifications,
		readError: (state) => {
			return state.error;
		},
	},
	mutations: {
		SET_TOTAL_PAGES(state, payload) {
			state.totalPages = payload;
		},
		SET_ID(state, payload) {
			state.id = payload;
		},
		SET_NOTIFICATIONS(state, payload) {
			state.notifications = payload;
		},
		SET_ERROR(state, payload) {
			state.error = payload;
		},
	},
	actions: {
		async getNotifications({ commit, getters ,rootGetters }) {
			let token = rootGetters['loginStore/readToken']
			let getNotificationsRes = await getNotifications
				.getNotifications(token, getters.readId)
				.then((data) => {
					if (data) {
						const response = data.items || data;
						Array.isArray(response)
							? commit("SET_NOTIFICATIONS", response)
							: commit("SET_NOTIFICATIONS", [response]);
						commit("SET_TOTAL_PAGES", data.total);
						return true;
					} else {
						commit("SET_NOTIFICATIONS", []);
						commit("SET_TOTAL_PAGES", null);

						return false;
					}
				})
				.catch((error) => {
					if (error.response) {
						let message =
							error.response.data.message ||
							error.response.data.detail[0].msg;
						message == "Token expired"
							? commit("loginStore/SET_ACCESS_TOKEN", null, {
									root: true,
							  })
							: message
							? commit("SET_ERROR", message)
							: commit("SET_ERROR", "Algo salió mal");
					} else {
						console.log(error);
						commit("SET_ERROR", error);
					}
				});
			return getNotificationsRes;
		},
	},
};
