import cmfApi from "./index";

export const getRegionsApi = (headers) => cmfApi.get("/regions", { headers });

export const createRegionApi = (data, headers) =>
	cmfApi.post("/regions", data, { headers });

export const updateRegionApi = (id, data, headers) => {
	return cmfApi.post(`/regions/${id}`, data, { headers });
};

export const deleteRegionApi = (id, headers) => {
	return cmfApi.delete(`/regions/${id}`, { headers });
};
