<script>
import MainLayout from '@/layouts/MainLayout.vue';

export default {
	components: { MainLayout },
};
</script>

<template>
	<div id="app" class="app">
		<MainLayout></MainLayout>
	</div>
</template>
