import cmfApi from "../index";

const API_URL = "/users/photo";

function updatePhoto(token, photo) {
	const config = {
		headers: {
			"Content-Type": "multipart/form-data",
			Authorization: `Bearer ${token}`,
		},
	};

	const body = new FormData();
	body.append("photo", photo);

	return new Promise(function (resolve, reject) {
		cmfApi.post(API_URL, body, config).then(
			(response) => {
				var result = response.data;
				resolve(result);
			},
			(error) => {
				reject(error);
			}
		);
	});
}

export { updatePhoto };
