import cmfApi from "../index";

const API_URL = "/users/search?query=";

function getUsers(token, query, pagination) {
	const config = {
		headers: {
			Accept: "application/json",
			Authorization: `Bearer ${token}`,
		},
	};

	const URL = pagination
		? `${API_URL}${query}&page=${pagination.page}&size=${pagination.size}`
		: `${API_URL}${query}&page=1&size=15`;

	return new Promise(function (resolve, reject) {
		cmfApi.get(URL, config).then(
			(response) => {
				var result = response.data;
				resolve(result);
			},
			(error) => {
				reject(error);
			}
		);
	});
}

export { getUsers };
