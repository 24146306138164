import {
	getRegionsApi,
	createRegionApi,
	updateRegionApi,
	deleteRegionApi,
} from "@/api/regionsApi";

export const regionActions = async ({ state, commit, rootState }, payload) => {
	const { region } = state,
		{ id } = region,
		{ accessToken } = rootState.loginStore,
		headers = {
			Authorization: `Bearer ${accessToken}`,
		};

	let action;

	try {
		switch (payload) {
			case "getRegions":
				action = await getRegionsApi(headers).then(({ data }) => {
					if (data) {
						commit("SET_REGIONS", data);
						return true;
					} else {
						commit("SET_REGIONS", []);
						return false;
					}
				});
				break;
			case "createRegion":
				action = await createRegionApi(region, headers);
				break;
			case "updateRegion":
				action = await updateRegionApi(id, region, headers);
				break;
			case "deleteRegion":
				action = await deleteRegionApi(id, headers);
				break;
			default:
				break;
		}
	} catch (error) {
		if (error.response) {
			let message =
				error.response.data.message ||
				error.response.data.detail[0].msg;
			message == "Token expired"
				? commit("loginStore/SET_ACCESS_TOKEN", null, {
						root: true,
				  })
				: message
				? commit("SET_ERROR", message)
				: commit("SET_ERROR", "Algo salió mal");
		} else {
			console.log(error);
			commit("SET_ERROR", error);
		}
	}

	return action;
};
