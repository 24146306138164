import CryptoJS from "crypto-js";
window.generateToken = function (code) {
	var plaintext = code;
	var pwhash = CryptoJS.SHA1(CryptoJS.enc.Utf8.parse("ulx&T@u5d!nnQwvA"));
	var key = CryptoJS.enc.Hex.parse(
		pwhash.toString(CryptoJS.enc.Hex).substr(0, 32)
	);

	var encrypted = CryptoJS.AES.encrypt(plaintext, key, {
		mode: CryptoJS.mode.ECB,
		padding: CryptoJS.pad.Pkcs7,
	});

	var ciphertext = encrypted.ciphertext.toString(CryptoJS.enc.Hex);
	console.log(ciphertext);
};
window.generateToken2 = function (code) {
	var plaintext = code;
	var pwhash = CryptoJS.SHA1(CryptoJS.enc.Utf8.parse("RaG&j!9G2zczJbka"));
	var key = CryptoJS.enc.Hex.parse(
		pwhash.toString(CryptoJS.enc.Hex).substr(0, 32)
	);

	var encrypted = CryptoJS.AES.encrypt(plaintext, key, {
		mode: CryptoJS.mode.ECB,
		padding: CryptoJS.pad.Pkcs7,
	});

	var ciphertext = encrypted.ciphertext.toString(CryptoJS.enc.Hex);
	console.log(ciphertext);
};
