export const SET_PROFILE = (state, payload) => {
	if (payload == "restore") {
		const storage = JSON.parse(localStorage.getItem("ru_profile"));
		if (storage) {
			if (storage.user) {
				state.profile = storage.user;
			} else {
				state.profile = {};
			}
		}
	} else {
		localStorage.setItem(
			"ru_profile",
			JSON.stringify({ user: payload })
		);
		state.profile = payload;
	}
};
export const SET_ERROR = (state, payload) => {
	state.error = payload;
};
