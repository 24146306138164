<script>
export default {};
</script>

<template>
	<div class="main-layout">
		<router-view />
	</div>
</template>

<style lang="scss">
::-webkit-scrollbar {
	width: 0.5rem;
}
/* Track */
::-webkit-scrollbar-track {
	background: $gray-light;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: $blue;
}

.main-layout {
	font-family: "Muller Regular";
	color: $black;

	.layout {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		min-height: 100vh;
		min-height: -webkit-fill-available;
		width: 100vw;
		overflow-y: scroll;
	}

	.main {
		margin-top: 3rem;
		margin-bottom: 3rem;
		padding-left: 0.75rem;
		padding-right: 0.75rem;
	}
}
</style>
