import * as getUsers from "@/api/users/get";

export default {
	namespaced: true,
	state: {
		id: null,
		users: [],
		filter: null,
		totalPages: null,
		pagination: null,
		error: null,
	},
	getters: {
		readTotalPages: (state) => state.totalPages,
		readPagination: (state) => state.pagination,
		readId: (state) => state.id,
		readUsers: (state) => state.users,
		readFilter: (state) => state.filter,
		readError: (state) => {
			return state.error;
		},
	},
	mutations: {
		SET_TOTAL_PAGES(state, payload) {
			state.totalPages = payload;
		},
		SET_PAGINATION(state, payload) {
			state.pagination = payload;
		},
		SET_ID(state, payload) {
			state.id = payload;
		},
		SET_USERS(state, payload) {
			state.users = payload;
		},
		SET_FILTER(state, payload) {
			state.filter = payload;
		},
		SET_ERROR(state, payload) {
			state.error = payload;
		},
	},
	actions: {
		async getUsers({ commit, getters, rootGetters }) {
			const token = rootGetters["loginStore/readToken"];
			let getUsersRes = await getUsers
				.getUsers(
					token,
					getters.readId,
					getters.readFilter,
					getters.readPagination
				)
				.then((data) => {
					if (data) {
						const response = data.items || data;
						Array.isArray(response)
							? commit("SET_USERS", response)
							: commit("SET_USERS", [response]);
						commit("SET_TOTAL_PAGES", data.total);
						return true;
					} else {
						commit("SET_USERS", []);
						commit("SET_TOTAL_PAGES", null);

						return false;
					}
				})
				.catch((error) => {
					if (error.response) {
						let message =
							error.response.data.message ||
							error.response.data.detail[0].msg;
						message == "Token expired"
							? commit("loginStore/SET_ACCESS_TOKEN", null, {
									root: true,
							  })
							: message
							? commit("SET_ERROR", message)
							: commit("SET_ERROR", "Algo salió mal");
					} else {
						console.log(error);
						commit("SET_ERROR", error);
					}
				});
			return getUsersRes;
		},
	},
};
