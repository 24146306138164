	export const SET_ACCESS_TOKEN = (state, payload) => {
		if (payload == "restore") {
			const storage = JSON.parse(localStorage.getItem("ru_token"));
			if (storage) {
				if (storage.token) {
					state.accessToken = storage.token;
				} else {
					state.accessToken = null;
				}
			}
		} else {
			localStorage.setItem(
				"ru_token",
				JSON.stringify({ token: payload })
			);
			state.accessToken = payload;
		}
	};
	export const SET_CREDENTIALS = (state, payload) => {
		state.credentials = payload;
	};
	export const SET_HASH = (state, payload) => {
		state.hash = payload;
	};
	export const SET_ERROR =(state, payload) => {
		state.error = payload;
	};

