import cmfApi from "../index";

const API_URL = "/territories";

function getTerritories(token, id) {
	const config = {
		headers: {
			Accept: "application/json",
			Authorization: `Bearer ${token}`,
		},
	};


	const URL = id
		? `${API_URL}/${id}`
		: API_URL;

	return new Promise(function (resolve, reject) {
		cmfApi.get(URL, config).then(
			(response) => {
				var result = response.data;
				resolve(result);
			},
			(error) => {
				reject(error);
			}
		);
	});
}

export { getTerritories };
