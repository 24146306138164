import axios from "axios";

const API_URL = "/users";

function deleteUser(token,id) {

	const config = {
		headers: {
			Accept: "application/json",
			Authorization: `Bearer ${token}`,
		},
	};

	return new Promise(function (resolve, reject) {
		cmfApi.delete(`${API_URL}/${id}`, config).then(
			(response) => {
				var result = response.data;
				resolve(result);
			},
			(error) => {
				reject(error);
			}
		);
	});
}

export { deleteUser };
