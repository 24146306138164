import {
	getFaqsApi,
	createFaqApi,
	updateFaqApi,
	deleteFaqApi,
} from "@/api/faqsApi";

export const faqsActions = async ({ state, commit, rootState }, payload) => {
	const { faqItem } = state,
		{ id } = faqItem,
		{ accessToken } = rootState.loginStore,
		headers = {
			Authorization: `Bearer ${accessToken}`,
		};

	let action;

	try {
		switch (payload) {
			case "getFaqs":
				action = await getFaqsApi(headers).then(({ data }) => {
					if (data) {
						commit("SET_FAQS", data);
						return true;
					} else {
						commit("SET_FAQS", []);
						return false;
					}
				});
				break;
			case "createFaq":
				action = await createFaqApi(faqItem, headers);
				break;
			case "updateFaq":
				action = await updateFaqApi(id, faqItem, headers);
				break;
			case "deleteFaq":
				action = await deleteFaqApi(id, headers);
				break;
			default:
				break;
		}
	} catch (error) {
		if (error.response) {
			let message =
				error.response.data.message ||
				error.response.data.detail[0].msg;
			message == "Token expired"
				? commit("loginStore/SET_ACCESS_TOKEN", null, {
						root: true,
				  })
				: message
				? commit("SET_ERROR", message)
				: commit("SET_ERROR", "Algo salió mal");
		} else {
			console.log(error);
			commit("SET_ERROR", error);
		}
	}

	return action;
};
