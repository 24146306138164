import cmfApi from "../index";

const API_URL = "/users";

function getUsers(token, id, filter, pagination) {
	const config = {
		headers: {
			Accept: "application/json",
			Authorization: `Bearer ${token}`,
		},
	};

	const URL = id
		? `${API_URL}/${id}`
		: filter
		? pagination
			? `${API_URL}/by/${filter.name}/${filter.id}?page=${pagination.page}&size=${pagination.size}`
			: `${API_URL}/by/${filter.name}/${filter.id}`
		:pagination ? `${API_URL}?page=${pagination.page}&size=${pagination.size}` : API_URL;

	return new Promise(function (resolve, reject) {
		cmfApi.get(URL, config).then(
			(response) => {
				var result = response.data;
				resolve(result);
			},
			(error) => {
				reject(error);
			}
		);
	});
}

export { getUsers };
