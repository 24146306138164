import cmfApi from "./index";

export const getFaqsApi = (headers) => cmfApi.get("/faqs", { headers });

export const createFaqApi = (data, headers) =>
	cmfApi.post("/faqs", data, { headers });

export const updateFaqApi = (id, data, headers) => {
	return cmfApi.post(`/faqs/${id}`, data, { headers });
};

export const deleteFaqApi = (id, headers) => {
	return cmfApi.delete(`/faqs/${id}`, { headers });
};
