import * as getRank from "@/api/votes/rank";

export default {
	namespaced: true,
	state: {
		id:null,
		rank: {},
		error: null,
	},
	getters: {
		readId: (state) => state.id,
		readRank: (state) => state.rank,
		readError: (state) => {
			return state.error;
		},
	},
	mutations: {
		SET_ID(state, payload) {
			state.id = payload;
		},
		SET_RANK(state, payload) {
			state.rank = payload;
		},
		SET_ERROR(state, payload) {
			state.error = payload;
		},
	},
	actions: {
		async getRank({ commit, getters ,rootGetters }) {
			let token = rootGetters['loginStore/readToken']
			let getRankRes = await getRank
				.getRank(token, getters.readId)
				.then((data) => {
					if(data){
						commit("SET_RANK", data);
						return true;
					} else{
						commit("SET_RANK", {});
						return false;
					}
				})
				.catch((error) => {
					if (error.response) {
						let message =
							error.response.data.message ||
							error.response.data.detail[0].msg;
						message == "Token expired"
							? commit("loginStore/SET_ACCESS_TOKEN", null, {
									root: true,
							  })
							: message
							? commit("SET_ERROR", message)
							: commit("SET_ERROR", "Algo salió mal");
					} else {
						console.log(error);
						commit("SET_ERROR", error);
					}
				});
			return getRankRes;
		},
	},
};
