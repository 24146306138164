import Vue from "vue";
import Vuex from "vuex";

import loading from "./modules/loading";

//LOGIN
import loginStore from "./modules/login/";
import auth from "./modules/auth/";
//SET_TOUR_COMPLETE
import tourComplete from "./modules/tourComplete";

//USERS
import createUser from "./modules/users/create";
import getUsers from "./modules/users/get";
import deleteUser from "./modules/users/delete";
import updateUser from "./modules/users/update";
import searchUser from "./modules/users/search";
import updatePhoto from "./modules/users/photo";
import updateName from "./modules/users/updateName";

//PERIODS
import getPeriods from "./modules/periods/get";
import createPeriod from "./modules/periods/create";
import deletePeriod from "./modules/periods/delete";
import updatePeriod from "./modules/periods/update";

//REACTIONS
import getReactions from "./modules/reactions/get";

//ROLES
import getRoles from "./modules/roles/get";
import createRole from "./modules/roles/create";
import deleteRole from "./modules/roles/delete";
import updateRole from "./modules/roles/update";

//TERRITORIES
import getTerritories from "./modules/territories/get";
import createTerritory from "./modules/territories/create";
import deleteTerritory from "./modules/territories/delete";
import updateTerritory from "./modules/territories/update";

//REGIONS
import regionsStore from "./modules/regions/";

//AGENCIES
import agenciesStore from "./modules/agencies/";

//CULTURES
import getCultures from "./modules/cultures/get";
import createCulture from "./modules/cultures/create";
import deleteCulture from "./modules/cultures/delete";
import updateCulture from "./modules/cultures/update";

//VOTES
import getVotes from "./modules/votes/current";
import getVotesCount from "./modules/votes/countCurrent";
import getVotesPending from "./modules/votes/pending";
import createVote from "./modules/votes/create";
import deleteVote from "./modules/votes/delete";
import votesStore from "./modules/votesCount";

//RANKS
import getRank from "./modules/votes/rank";

//STATS
import getTopTerritories from "./modules/stats/topTerritories";
import getTopRegions from "./modules/stats/topRegions";
import getTopAgencies from "./modules/stats/topAgencies";
import getTopUsers from "./modules/stats/topUsers";

//REPORTS
import getReport from "./modules/reports/get";

//NOTIFICATIONS
import getNotifications from "./modules/notifications/get";
import hasReadNotification from "./modules/notifications/hasRead";

//FAQS
import faqsStore from "./modules/faqs";

Vue.use(Vuex);

const store = new Vuex.Store({
	modules: {
		loading,

		loginStore,
		auth,
		tourComplete,

		//USERS
		getUsers,
		createUser,
		deleteUser,
		updateUser,
		searchUser,
		updatePhoto,
		updateName,

		//PERIODS
		getPeriods,
		createPeriod,
		deletePeriod,
		updatePeriod,

		//REACTIONS
		getReactions,

		//ROLES
		getRoles,
		createRole,
		deleteRole,
		updateRole,

		//TERRITORIES
		getTerritories,
		createTerritory,
		deleteTerritory,
		updateTerritory,

		//REGIONS
		regionsStore,

		//AGENCIES
		agenciesStore,

		//CULTURES
		getCultures,
		createCulture,
		deleteCulture,
		updateCulture,

		//VOTES
		getVotes,
		getVotesCount,
		getVotesPending,
		createVote,
		deleteVote,
		votesStore,

		//RANKS
		getRank,

		//STATS
		getTopTerritories,
		getTopRegions,
		getTopAgencies,
		getTopUsers,

		//REPORTS
		getReport,

		//NOTIFICATIONS
		getNotifications,
		hasReadNotification,

		//FAQS
		faqsStore,
	},
});

export default store;
