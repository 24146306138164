import cmfApi from "../index";

const API_URL = "/notifications/read";

function hasRead(token, id) {
	const config = {
		headers: {
			Accept: "application/json",
			Authorization: `Bearer ${token}`,
		},
	};

	const URL = id
		? `${API_URL}/${id}`
		: `${API_URL}/all`;

	return new Promise(function (resolve, reject) {
		cmfApi.post(URL, null, config).then(
			(response) => {
				var result = response.data;
				resolve(result);
			},
			(error) => {
				reject(error);
			}
		);
	});
}

export { hasRead };
