import * as getVotesPending from "@/api/votes/pending";

export default {
	namespaced: true,
	state: {
		id: null,
		votes: {},
		error: null,
	},
	getters: {
		readId: (state) => state.id,
		readVotes: (state) => state.votes,
		readError: (state) => {
			return state.error;
		},
	},
	mutations: {
		SET_ID(state, payload) {
			state.id = payload;
		},
		SET_VOTES(state, payload) {
			state.votes = payload;
		},
		SET_ERROR(state, payload) {
			state.error = payload;
		},
	},
	actions: {
		async getVotesPending({ commit, getters, rootGetters }) {
			let token = rootGetters["loginStore/readToken"];
			let getVotesPendingRes = await getVotesPending
				.getVotesPending(token, getters.readId)
				.then((data) => {
					if (data) {
						commit("SET_VOTES", data);
						return true;
					} else {
						commit("SET_VOTES", null);
						return false;
					}
				})
				.catch((error) => {
					if (error.response) {
						let message =
							error.response.data.message ||
							error.response.data.detail[0].msg;
						message == "Token expired"
							? commit("loginStore/SET_ACCESS_TOKEN", null, {
									root: true,
							  })
							: message
							? commit("SET_ERROR", message)
							: commit("SET_ERROR", "Algo salió mal");
					} else {
						console.log(error);
						commit("SET_ERROR", error);
					}
				});
			return getVotesPendingRes;
		},
	},
};
