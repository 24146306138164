import axios from "axios";

const API =
    process.env.NODE_ENV === "development"
        ? process.env.VUE_APP_STAGE_API
        : process.env.VUE_APP_PRODUCTION_API;

console.log(API);

const cmfApi = axios.create({
    baseURL: API,
});

export default cmfApi;

